html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
    font-family: sans-serif;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

* {
    box-sizing: border-box;
}

body,
#root,
#root > * {
    height: 100vh;
    overflow: auto;
}

.signin {
    background-color: white;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signin button {
    border-radius: 4px;
    border: solid 2px #333;
    color: #333;
    background-color: white;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 20px;
}

.gutter {
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: #7777770a;
    border-radius: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.gutter:hover {
    background-color: #7777771a;
}

.gutter.gutter-horizontal {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
    cursor: col-resize;
}

.hoverableCell:hover, .hoverableCell.editing {
    background-color: #00c8ff0d;
}

.split {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.ScrollAreaRoot {
    width: 100%;
    height: 100%;
    --scrollbar-size: 10px;
}

.ScrollAreaViewport {
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.ScrollAreaScrollbar {
    display: flex;
    /* ensures no selection */
    user-select: none;
    /* disable browser handling of all panning and zooming gestures on touch devices */
    touch-action: none;
    padding: 2px;
    /* background: #77777711; */
    transition: background 160ms ease-out;
}
.ScrollAreaScrollbar:hover {
    /* background: #77777733; */
}
.ScrollAreaScrollbar[data-orientation="vertical"] {
    width: 10px;
}
.ScrollAreaScrollbar[data-orientation="horizontal"] {
    flex-direction: column;
    height: 10px;
}

.ScrollAreaThumb {
    flex: 1;
    background: #1234;
    border-radius: 10px;
    position: relative;
}
/* increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html */
.ScrollAreaThumb::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
}

.ScrollAreaCorner {
    /* background: #777777; */
}

.highlight {
    background-color: #feff9c !important;
}

::highlight(highlights_faint) {
    background-color: #f9f9d9 !important;
}

::highlight(highlights_sharp) {
    background-color: #fcff5f !important;
}

::highlight(search_faint) {
    background-color: #adff2f33 !important;
}


::highlight(search_sharp) {
    background-color: greenyellow !important;
}

.hoverborder {
    border: thin solid blue;
}

.variable {
    background-color: #feff9c !important;
    border-radius: 3px;
}

.variable.disabled {
    opacity: 0.5;
}

.response-markdown {
    max-width: 100%;
    overflow: auto;
}

.response-markdown ol,
.response-markdown ul,
.response-markdown p,
.response-markdown table {
    margin: 7px 0;
}

.response-markdown ol,
.response-markdown ul {
    list-style: initial;
}
.response-markdown li {
    padding-left: 10px;
    margin-left: 20px;
}

.response-markdown h1 {
    font-size: 1.2em;
}
.response-markdown h2 {
    font-size: 1.1em;
}
.response-markdown h3 {
    font-size: 1em;
}

.response-markdown table {
    width: 100%;
    overflow: auto;
}

.response-markdown table tr {
    border-bottom: 1px solid #00789933;
}

.response-markdown table td {
    padding: 2px 6px;
}

.response-markdown table tr:nth-of-type(even) {
    background-color: #0078990a;
}

.response-markdown table tr:last-of-type {
    border-bottom: 2px solid #00789999;
}

.clic table thead th{
    font-weight: normal !important;
    padding:16px !important;
}

.clic table tr td {
    text-align: center !important;
}

td.clic-officer {
    min-width:150px;
}


form.dynamic-form {
    display: inline-flex;
    flex-direction: column;
    width:100%;
    gap: 6px;
}

div.form-item {
    color: red;
}

div.form-item label{
    color: #888;
    font-size: 11px;
    display: block;
}


div.form-item input, div.form-item select {
    /* color: blue; */
    padding: 2px;
    text-align: right;
    border: 1px solid #bbb;
}

div.form-item-array {
    display: flex;
    flex-direction: column; 
    gap: 4px;
}

div.form-item-array li{
    list-style: square outside;
}
div.form-item-array li::marker{
    color: #666;
}

div.form-item-array ul{
    padding-bottom:12px;
    padding-left:14px;
}



div.form-item-array label{
    color: #888;
    font-size: 11px;
    display: block;
}

div.form-item-array-element {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
}



div.form-item-array-element > .hoverableCell {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

div.form-item-array-element button{
    height: 30px;
    width: 30px;
    font-size: 12px;
    /* margin-top:17px; */
    display: none;
}

div.form-item-array-element:hover button, .hoverableCell.editing div.form-item-array-element button{
    display: inline-flex;
}

.hoverableCell:hover input {
    border-color: #000;
    background-color:#f9f9d9 ;
}


div.form-item-array-element:nth-of-type(n + 2) button {
    margin-top: 0px;
}

div.form-item-array-element:nth-of-type(n + 2) label {
    display: none;
}



div.form-error {
    color: rgb(199, 0, 0);
    font-size: 11px;
    font-style: italic;
}

button.review-cancel { 
    background-color: #ccc !important;
    color: white !important;
}

button.review-cancel:hover { 
    background-color: #888 !important;
    color: white !important;
}

button.review-accept { 
    background-color: rgb(19, 175, 0, 0.4);
    color: white !important;
}

button.review-accept.accepted, button.review-accept.corrected { 
    background-color: rgb(19, 175, 0) !important;
}

button.review-accept:hover { 
    background-color: rgb(19, 175, 0) !important;
}

button.review-reject { 
    background-color: rgb(190, 0,0, 0.4) !important;
    color: white !important;
}

button.review-reject.rejected{ 
    background-color: rgb(190, 0,0) !important;
}

button.review-reject:hover { 
    background-color: rgb(190, 0,0) !important;
}


/* these control the styling of datapoints as "table-style" */


.review-panel .review-card:nth-of-type(n+3) .table-style .form-item-label {
    display: none;
}

.review-panel .review-card:nth-of-type(2) .table-style .form-item-label {
    font-size: 12px;
}

.table-style .form-item-label {
    color: #555;
    font-size: 12px;
    padding-bottom: 6px; 
    height: 45px;
}

/* 
.review-panel .review-card {
    margin-bottom: 4px;
} */